import {getLinkCode} from '@/modules/app-design/api/search'

export default {
  data() {
    return {

    }
  },
  computed: {

  },
  methods: {
    getWxLink(obj) {
      const link = obj.jump_link || {
        jump_type: 'page',
        jump_config: {
          path: obj.mp_link || '',
          view: {
            id: '',
            system_page: '',
            is_system: 0,
          },
        },
      }
      return link
    },
    getWxPathParams(path) {
      if (path.indexOf('?') === -1) {
        return ''
      } else {
        return path.slice(path.indexOf('?') + 1)
      }
    },
    getWxPathPath(path) {
      if (path.indexOf('?') === -1) {
        return path
      } else {
        const rawPath = path.split('?')
        return rawPath[0]
      }
    },
    getWxPath(jump_link) {
      const { jump_type, jump_config } = jump_link

      let path = '', name = ''

      if (
          jump_type === 'page' ||
          jump_type === 'view' ||
          jump_type === 'path'
      ) {
        if (jump_config.view?.id && jump_config.view?.is_system !== 1) {
          path = `/sub/custom/pages/custom?id=${jump_config.view.id}`
          console.log(jump_config)
        } else if (
            jump_config.view?.system_page &&
            jump_config.view?.is_system === 1
        ) {
          path = `/sub/custom/pages/custom?system_page=${jump_config.view.system_page}`
          console.log(jump_config)
        } else if (jump_config.path) {
          path = jump_config.path
          // name = jump_config.name
          console.log(jump_config)
        }
      }

      // const splashPath = '/main/pages/splash/splash'
      // console.log(path, '----------------generatePageUrl----------------')
      // const type = 'page'
      // let scene = `type=${type}`
      // if (path) scene += `&url=${encodeURIComponent(path)}`
      /**
       * 最大32个可见字符，只支持数字，大小写英文以及部分特殊字符：!#$&'()*+,/:;=?@-._~，
       * 其它字符请自行编码为合法字符（因不支持%，中文无法使用 urlencode 处理，请使用其他编码方式）
       */
      // if (name) scene += `&name=${name}` // 不能传中文哦好像
      // console.log(scene)
      // const shareUrl = `${splashPath}?show=0&url=${encodeURIComponent(path)}`
      // console.log(shareUrl, '----------------shareUrl--------------')
      // path = shareUrl

      if (path) {
        if (path.indexOf('/') === 0) {
          return path.slice(1)
        }
      }

      return ''
    },

    /**
     * 获取二维码 base64 图片
     * @param row                   行数据 scope.row
     * @param mp_version            trial | release
     * @returns {Promise<unknown>}
     */
    showCode(row, mp_version = 'trial') {
      return new Promise((resolve) => {
        getLinkCode({
          platform: ['trial', 'release'].includes(mp_version) ? 'mp-weixin' : 'h5',
          path:
              !['trial', 'release'].includes(mp_version)
                  ? row.mp_link
                  : this.getWxPathPath(this.getWxPath(this.getWxLink(row))),
          scene: this.getWxPathParams(this.getWxPath(this.getWxLink(row))),
          env_version: ['trial', 'release'].includes(mp_version) ? mp_version : '', // release or trial
        })
            .then(({ data, msg }) => {
              // this.$previewImage([data.qrcode])
              resolve(data.qrcode)
            })
            .catch(() => {})
      })
    },
  }
}