import api from '@/base/utils/request';

// 列表数据
export const positionType = data => {
  return api({
    url: '/admin/cyc/banner/positionType',
    method: 'post',
    data
  });
};

export const getDetail = data => {
  return api({
    url: '/admin/cyc/banner/detail',
    method: 'post',
    data
  })
}

export const saveDetail = data => {
  return api({
    url: '/admin/cyc/banner/save',
    method: 'post',
    data
  })
}

export const getArticles = data => {
  return api({
    url: '/admin/cyc/banner/articles',
    method: 'post',
    data
  })
}

export const getActivities = data => {
  return api({
    url: '/admin/cyc/banner/activities',
    method: 'post',
    data
  })
}

export const getBrands = data => {
  return api({
    url: '/admin/cyc/banner/brands',
    method: 'post',
    data
  })
}

// 获取所有来源
export const AllSource = data => {
  return api({
    url: "/admin/portal/source/all",
    method: "post",
    data
  });
};