<template>
  <div class="content" v-loading="loading">
    <el-form
      size="medium"
      label-width="90px"
      :rules="rules"
      ref="advertForm"
      :model="advertForm"
      class="medium-form"
      @submit.native.prevent
    >
      <el-form-item label="广告名称：" prop="name">
        <el-input v-model="advertForm.name" placeholder="请输入名称"></el-input>
      </el-form-item>
      <el-form-item label="广告分类：" prop="category_id">
        <div class="flex">
          <el-select v-model="advertForm.category_id">
            <el-option
              v-for="item in categoryIdArr"
              :label="item.name"
              :value="item.id"
              :key="item.id"
            ></el-option>
          </el-select>
          <AddCategoryButton
            :style="{
              marginLeft: '12px',
            }"
            :updateList="getCategoryOptions"
            :getApi="getApi"
          ></AddCategoryButton>
        </div>
      </el-form-item>
      <el-form-item label="广告类型：" prop="banner_type">
        <el-select v-model="advertForm.banner_type">
          <el-option
            v-for="item in bannereTypeArr"
            :label="item.name"
            :value="item.id"
            :key="item.id"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item
        label="推广标题："
        v-if="[0, 2, 3].includes(advertForm.banner_type)"
      >
        <el-input
          type="text"
          placeholder="请输入标题"
          v-model="advertForm.cover_title"
        ></el-input>
      </el-form-item>
      <el-form-item
        label="推广图："
        prop="image"
        v-if="[0, 2, 1].includes(advertForm.banner_type)"
      >
        <single-media-wall
          v-model="advertForm.image"
          :width="194"
          :height="130"
          :border-radius="4"
          directly
          :show-cut="true"
          :ratio="ratioList"
        >
        </single-media-wall>
        <el-input
          type="text"
          v-show="false"
          v-model="advertForm.image"
        ></el-input>
        <div class="form-tip">
          <div>
            <span
              class="el-icon-info"
              style="font-size: 15px; color: #c0c4cc"
            ></span>
            <span> 推荐尺寸： </span>
          </div>
          <!--          <div>轮播图（模板1）为 335px*134px</div>-->
          <!--          <div>轮播图（模板2）为 343px*150px</div>-->
          <!--          <div>轮播图（模板3）为 375px*232px</div>-->
          <!--          <div>顶部模板为 375px*120px</div>-->
          <!--          <div>弹窗广告为 323px*430px 或 3:4比例</div>-->
          <div :key="ratioDesc.ratio" v-for="ratioDesc in ratioList">
            {{ ratioDesc.desc }}
          </div>
        </div>
      </el-form-item>
      <el-form-item
        label="视频："
        prop="video"
        v-if="[3].includes(advertForm.banner_type)"
      >
        <single-media-wall
          v-model="advertForm.video"
          :width="194"
          :height="135"
          :borderRadius="4"
          addText="上传视频"
          UploadType="video"
        />
      </el-form-item>
      <el-form-item
        label="图文："
        prop="content"
        v-if="[2].includes(advertForm.banner_type)"
      >
        <tinymce
          id="tinymce-editor"
          ref="editor"
          @choose="handleToolBarClick"
          v-model="advertForm.content"
          show-article-import
          style="max-width: 540px"
        />
      </el-form-item>

      <!-- <el-form-item label="排序：">
        <el-input v-model="advertForm.sort" placeholder="请输入排序"></el-input>
      </el-form-item> -->

      <el-form-item
        label="广告平台："
        prop="jump_link"
        v-if="[0, 1].includes(advertForm.banner_type)"
      >
        <el-checkbox-group
          v-model="advertForm.platformList"
          @change="platformChange"
        >
          <el-checkbox
            :disabled="
              advertForm.platformList.length === 1 &&
              advertForm.platformList[0] === 'mp-weixin'
            "
            label="mp-weixin"
            >小程序</el-checkbox
          >
          <el-checkbox
            :disabled="
              advertForm.platformList.length === 1 &&
              advertForm.platformList[0] === 'h5'
            "
            label="h5"
            >H5</el-checkbox
          >
        </el-checkbox-group>

        <!-- {{ advertForm.platform }} -->
      </el-form-item>
      <el-form-item
        label="跳转链接："
        prop="jump_link"
        v-if="[0, 1].includes(advertForm.banner_type)"
      >
        <!--        <el-button v-if="advertForm.current_position_index === ''" disabled>请选择广告投放位置</el-button>-->
        <el-button
          class="jump_link"
          type="default"
          plain
          @click="showLinkConfigDialog = true"
        >
          {{
            advertForm.jump_link
              ? advertForm.jump_link.jump_config.name
              : '设置链接地址'
          }}
        </el-button>
        <el-button
          v-if="advertForm.jump_link"
          type="text"
          @click="advertForm.jump_link = null"
          >删除
        </el-button>
        <!--        <el-input v-show="false" v-model="advertForm.jump_link"></el-input>-->
      </el-form-item>
      <el-form-item label="推广时段：" prop="dateRange">
        <el-date-picker
          v-model="advertForm.dateRange"
          type="datetimerange"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          value-format="timestamp"
          format="yyyy-MM-dd HH:mm"
          time-arrow-control
        >
        </el-date-picker>
        <div>
          <el-checkbox
            :true-label="1"
            :false-label="0"
            v-model="advertForm.is_long_term"
          >
            <div class="form-tip">勾选后为长期推广，无需再选择日期</div>
          </el-checkbox>
        </div>
      </el-form-item>
      <el-form-item label="投放范围：" prop="scope_type">
        <el-select v-model="advertForm.scope_type">
          <el-option
            v-for="item in scopeTypeArr"
            :label="item.name"
            :value="item.id"
            :key="item.id"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item
        v-if="advertForm.banner_type === 1"
        label="投放频率："
        prop="frequency_config"
      >
        <div>
          出现次数
          <el-input
            style="width: 60px; margin-right: 5px"
            v-model="advertForm.frequency_config.times"
          ></el-input
          >(次/天), 频率<el-input
            style="width: 60px; margin-right: 5px"
            v-model="advertForm.frequency_config.interval_hour"
          ></el-input
          >小时
          <el-input
            style="width: 60px; margin-right: 5px"
            v-model="advertForm.frequency_config.interval_minute"
          ></el-input
          >分钟
          <div class="form-tip">
            <div>
              <span
                class="el-icon-info"
                style="font-size: 15px; color: #c0c4cc"
              ></span>
              <span>
                次数为 0 代表不限制，频率小时数和分钟数为 0 代表不限制
              </span>
            </div>
          </div>
        </div>
      </el-form-item>
      <el-form-item
        label="用户类型："
        prop="scope_user_type"
        v-if="advertForm.scope_type === 1"
      >
        <el-select multiple v-model="advertForm.scope_user_type">
          <el-option
            v-for="item in scopeUserTypeArr"
            :label="item.name"
            :value="item.id"
            :key="item.id"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item
        label="用户性别："
        prop="scope_genders"
        v-if="advertForm.scope_type === 2"
      >
        <el-select multiple v-model="advertForm.scope_genders">
          <el-option
            v-for="item in scopeGendersArr"
            :label="item.name"
            :value="item.id"
            :key="item.id"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item
        label="用户职务："
        prop="scope_positions"
        v-if="advertForm.scope_type === 3"
      >
        <template v-if="scopePositionsArr">
          <el-cascader
            style="width: calc(100%)"
            v-model="advertForm.scope_positions"
            placeholder="请选择职务"
            :options="scopePositionsArr"
            :props="props"
            clearable
            collapse-tags
          >
          </el-cascader>
        </template>
      </el-form-item>
    </el-form>
    <fixed-action-bar :z-index="2">
      <el-button type="primary" @click="saveConfig('advertForm', 0)"
        >发布</el-button
      >
      <el-button
        v-if="advertForm.is_draft === 1"
        type="warning"
        @click="saveConfig('advertForm', 1)"
        >存为草稿</el-button
      >
      <el-button @click="cancel">取消</el-button>
    </fixed-action-bar>
    <link-config-dialog
      @change="linkChange"
      jump_type="path"
      :platformNew="advertForm.platform"
      v-model="showLinkConfigDialog"
      :needReload="true"
    />
    <!-- 富文本插入图片 -->
    <media-selector v-model="showImageList" multiple @select="updateImages" />
    <media-selector
      v-model="showVideoList"
      type="video"
      multiple
      @select="updateVideos"
    />
    <media-selector
      v-model="showAudioList"
      type="audio"
      multiple
      @select="updateAudios"
    />
    <!-- 文章导入 -->
    <import-article v-model="isShowImportDialog" @confirm="getImportDetail" />
    <!-- 导入HTML -->
    <import-html-code v-model="isShowHtmlInput" />
  </div>
</template>

<script>
import { getDetail, saveDetail } from '../api/advert-edit'
import FixedActionBar from '../../../base/layout/FixedActionBar'
import SingleMediaWall from '../../common/components/SingleMediaWall'
import LinkConfigDialog from '../../app-design/components/Normal/LinkConfigDialog.vue'
import Tinymce from '@/base/components/Editor/Tinymce'
import MediaSelector from '../../common/components/MediaSelector'
import ImportArticle from '@/base/components/Editor/ImportArticle'
import ImportHtmlCode from '@/base/components/Editor/ImportHtmlCode'
import { categoryOptions, positionLevel } from '../api/banner-category'
import AddCategoryButton from '@/base/components/Base/AddCategoryButton.vue'
import { saveCategory } from '@/modules/advert/api/banner-category'

export default {
  components: {
    SingleMediaWall,
    FixedActionBar,
    LinkConfigDialog,
    Tinymce,
    MediaSelector,
    ImportArticle,
    ImportHtmlCode,
    AddCategoryButton,
  },
  data() {
    return {
      loading: true,
      advertForm: {
        id: 0,
        banner_type: '',
        name: '',
        location_id: '',
        sort: '',
        // jump_type: "",
        // jump_config: {
        //   item_id: "",
        //   item_name: "",
        //   url: "",
        // },
        jump_link: null,
        start_time: '',
        end_time: '',
        cover_title: '',
        image: '',
        // 时间戳数组
        dateRange: [],
        // 当前选中的位置下标
        current_position_index: 0,
        category_id: '',
        scope_type: '',
        scope_user_type: [],
        scope_genders: [],
        scope_positions: [],
        // 投放频率
        frequency_config: {
          times: 0, // 次数，0 代表不限制
          interval_hour: 0, //  间隔小时数与分钟数同时为 0 代表不限制
          interval_minute: 0,
        },
        content: '',
        video: {},
        is_long_term: 0,
        is_draft: 1,
        platform: 'all',
        platformList: ['mp-weixin', 'h5'],
      },
      categoryIdArr: [],
      bannereTypeArr: [
        { id: 0, name: '跳转链接' },
        { id: 1, name: '首页弹窗' },
        { id: 2, name: '图文' },
        { id: 3, name: '视频' },
      ],
      scopeTypeArr: [
        { id: 0, name: '所有人' },
        { id: 1, name: '用户类型' },
        { id: 2, name: '用户性别' },
        { id: 3, name: '用户职务' },
      ],
      scopeUserTypeArr: [
        { id: 1, name: '会员' },
        { id: 2, name: '非会员' },
        { id: 3, name: '游客' },
      ],
      scopeGendersArr: [
        { id: 1, name: '未填' },
        { id: 2, name: '男' },
        { id: 3, name: '女' },
      ],
      scopePositionsArr: [],
      rules: {
        name: [{ required: true, message: '请输入名称', trigger: 'blur' }],
        image: [{ required: true, message: '请选择推广图', trigger: 'change' }],
        dateRange: [
          {
            required: true,
            message: '请选择日期',
            validator: (rule, value, callback) => {
              if (
                this.advertForm.is_long_term === 0 &&
                !this.advertForm.dateRange?.length
              ) {
                callback(new Error('请选择推广时段'))
              } else {
                callback()
              }
            },
            trigger: 'change',
          },
        ],
        current_position_index: [
          { required: true, message: '请选择投放位置', trigger: 'change' },
        ],
        jump_link: [
          { required: false, message: '请选择跳转链接', trigger: 'change' },
        ],
        category_id: [
          { required: true, message: '请选择广告分类', trigger: 'change' },
        ],
        banner_type: [
          { required: true, message: '请选择广告类型', trigger: 'change' },
        ],
        scope_type: [
          { required: true, message: '请选择投放范围', trigger: 'change' },
        ],
        scope_user_type: [
          { required: true, message: '请选择用户类型', trigger: 'change' },
        ],
        scope_genders: [
          { required: true, message: '请选择用户性别', trigger: 'change' },
        ],
        scope_positions: [
          { required: true, message: '请选择用户职务', trigger: 'change' },
        ],
        video: [{ required: true, message: '请选择视频', trigger: 'change' }],
        content: [{ required: true, message: '请输入图文', trigger: 'blur' }],
      },
      showLinkConfigDialog: false,
      props: {
        multiple: true,
        label: 'name',
        value: 'id',
        children: 'position',
        emitPath: false,
        expandTrigger: 'hover',
      },
      // 禁用今天以前
      // pickerOptions: {
      //   disabledDate: (time) => {
      //     return this.selectableDate(time);
      //   },
      // },
      showImageList: false,
      showVideoList: false,
      showAudioList: false,
      isShowImportDialog: false,
      isShowHtmlInput: false,
      isShowSelectImg: false,
      isShowSelector: false,

      ratioList: [
        {
          ratio: `${335 * 3}:${134 * 3}`,
          desc: `轮播图（模板1）为 ${335 * 3}px*${134 * 3}px`,
        },
        {
          ratio: `${343 * 3}:${150 * 3}`,
          desc: `轮播图（模板2）为 ${343 * 3}px*${150 * 3}px`,
        },
        {
          ratio: `${375 * 3}:${232 * 3}`,
          desc: `轮播图（模板3）为 ${375 * 3}px*${232 * 3}px`,
        },
        {
          ratio: `${351 * 3}:${142 * 3}`,
          desc: `轮播图（模板4）为 ${351 * 3}px*${142 * 3}px`,
        },
        {
          ratio: `${375 * 3}:${120 * 3}`,
          desc: `顶部模板为 ${375 * 3}px*${120 * 3}px`,
        },
        {
          ratio: `3:4`,
          desc: `弹窗广告为 ${323 * 3}px*${430 * 3}px 或 3:4比例`,
        },
      ],
    }
  },
  watch: {
    'advertForm.current_position_index'(val) {
      console.log('val', val)
    },
  },
  created() {
    this.advertForm.id = this.$route.params.id || 0
    this.getCategoryOptions()
    this.getPositionLevel()
    if (this.advertForm.id) {
      this.getDetail()
    } else {
      this.loading = false
    }
  },
  methods: {
    platformChange(e) {
      console.log(e, 'e')
      if (e.length == 2) {
        this.advertForm.platform = 'all'
      } else if (e.length == 1) {
        this.advertForm.platform = e[0]
      } else {
        this.advertForm.platform = 'mp-weixin'
      }
    },
    getApi(data) {
      return saveCategory(data)
    },
    // 添加分类
    addCategory(data) {},
    /**
     * 富文本编辑器自定义工具栏按钮点击事件
     * @param {Object} e
     */
    handleToolBarClick(e) {
      switch (e.type) {
        case 'image':
          this.showImageList = true
          break
        case 'video':
          this.showVideoList = true
          break
        case 'audio':
          this.showAudioList = true
          break
        case 'import':
          this.isShowImportDialog = true
          break
        case 'html':
          this.isShowHtmlInput = true
          break
      }
    },
    /**
     * 逐一向富文本编辑器插入来自媒体库的图片
     * @param {Array} e 选中的图片数组
     */
    updateImages(e) {
      e.forEach((item) => {
        window.tinymce.execCommand(
          'mceReplaceContent',
          false,
          `<img src="${item.url}"/>`
        )
      })
    },
    updateVideos(e) {
      e.forEach((item) => {
        window.tinymce.execCommand(
          'mceReplaceContent',
          false,
          `<p><span class="mce-preview-object mce-object-video"
                contenteditable="false"
                data-mce-object="video"
                data-mce-p-controls="controls"
                data-mce-p-controlslist="nodownload"
                data-mce-p-allowfullscreen="true"
                data-mce-p-frameborder="no"
                data-mce-p-scrolling="no"
                data-mce-html=""
                data-mce-p-src="${item.url}">
                <video controls src="${item.url}"></video></span></p>
                <p><br/></p>`
          // `<video poster="${item.config.thumbnail}" controls src="${item.url}"></video>`
        )
      })
    },
    updateAudios(e) {
      e.forEach((item) => {
        window.tinymce.execCommand(
          'mceReplaceContent',
          false,
          `<p><span class="mce-object-audio"
            contenteditable="false"
            data-mce-object="audio"
            data-mce-p-controls="controls"
            data-mce-p-controlslist="nodownload"
            data-mce-p-frameborder="no"
            data-mce-p-scrolling="no"
            data-mce-html=""
            data-mce-p-src="${item.url}">
            <audio controls src="${item.url}"></audio></span></p>
            <p><br/></p>`
        )
      })
    },
    /**
     * 设置导入成功的文章内容
     * 设置封面图和标题
     * @param {Object} e
     */
    getImportDetail(e) {
      this.$set(this.article, 'content', e.content)
      this.$refs.editor.$emit('change', e.content)
      this.article.title = e.title
      this.article.images = []
      this.article.images.push({
        url: e.cover_image,
        type: 'image',
        id: new Date().getTime(),
      })
    },
    // 获取架构职务
    getPositionLevel() {
      positionLevel({
        is_list_tree: 0,
        is_show_all: 0,
        hashid_enable: 0,
        data_auth_filter: 0,
      })
        .then((res) => {
          this.scopePositionsArr = res.data
        })
        .catch(() => {})
    },
    getCategoryOptions() {
      categoryOptions().then((res) => {
        this.categoryIdArr = res.data
      })
    },
    getImage(e) {
      this.advertForm.image = e[0].url
    },
    getDetail() {
      getDetail({
        id: this.advertForm.id,
      })
        .then((res) => {
          this.advertForm = {
            ...this.advertForm,
            ...res.data,
            video: res.data.video?.url ? res.data.video : {},
          }
          if (this.advertForm.is_long_term === 0) {
            this.advertForm.dateRange = [
              res.data.start_time * 1000,
              res.data.end_time * 1000,
            ]
          } else {
            this.advertForm.dateRange = []
          }

          // 判断平台
          if (this.advertForm.platform === 'all') {
            this.advertForm.platformList = ['mp-weixin', 'h5']
          } else {
            this.advertForm.platformList = [this.advertForm.platform]
          }
          // this.advertForm.current_position_index = this.positionType.findIndex(
          //   (item) => item.id === res.data.location_id
          // );
          this.advertForm.current_position_index = 0
          this.loading = false
        })
        .catch((err) => {
          this.loading = false
        })
    },
    // 设置时间选择范围
    selectableDate(time) {
      return time.getTime() < new Date().getTime() - 24 * 60 * 60 * 1000
    },
    linkChange(val) {
      this.advertForm.jump_link = val
    },
    saveConfig(formName, is_draft) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.loading = true
          let params = {}
          if (
            this.advertForm.dateRange?.length &&
            this.advertForm.is_long_term === 0
          ) {
            params.start_time = this.advertForm.dateRange[0] / 1000
            params.end_time = this.advertForm.dateRange[1] / 1000
          } else {
            params.start_time = ''
            params.end_time = ''
          }
          this.advertForm.is_draft = is_draft
          saveDetail({
            ...Object.assign({}, this.advertForm, params),
          })
            .then((res) => {
              this.loading = false
              this.$message.success(res.msg)
              this.$router.back()
            })
            .catch((err) => {
              this.loading = false
            })
        } else {
          return false
        }
      })
    },
    cancel() {
      this.$router.back(-1)
    },
  },
}
</script>

<style lang="scss" scoped>
.content {
  width: 100%;

  .el-icon-search {
    margin-left: 10px;
  }

  .el-button {
    max-width: 320px;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.form-tip {
  font-size: 12px;
  color: #606266;
  line-height: 12px;
  margin-top: 15px;
  span + span {
    margin-left: 4px;
  }
  > div:nth-child(1) {
    margin-left: 0px;
  }
  > div {
    margin: 10px;
    margin-left: 23px;
  }
}
.tips {
  font-size: 12px;
  color: #c0c4cc;
  line-height: 24px;
}
</style>
