<template>
  <el-dialog
    @open="dialogOpen"
    title="查看"
    :visible="showCode"
    :width="codes.length === 2 ? '504px' : codes.length === 3 ? '800px' : '350px'"
    class="dialog-vertical"
    @close="cancel"
    :append-to-body="true"
    :close-on-click-modal="true"
    :close-on-press-escape="true"
  >
    <el-form v-loading="loading" size="medium" @submit.native.prevent>
      <el-form-item>
        <div class="code">
          <div class="content-box">
            <div class="item-box">
              <div class="code-box">
                <el-image fit="cover" :src="codes[0]"></el-image>
              </div>
              <el-button type="text">
                <a
                  v-if="codes.length > 1"
                  style="text-decoration: none"
                  :href="codes[0]"
                  download="小程序码（体验版）"
                >
                  下载小程序码（体验版）
                </a>
                <a
                  v-else
                  style="text-decoration: none"
                  :href="codes[0]"
                  download="二维码"
                >
                  下载二维码
                </a>
              </el-button>
            </div>
            <div v-if="codes.length > 1" class="item-box">
              <div class="code-box">
                <el-image fit="cover" :src="codes[1]"></el-image>
              </div>
              <el-button type="text">
                <a
                  style="text-decoration: none"
                  :href="codes[1]"
                  download="小程序码（正式版）"
                >
                  下载小程序码（正式版）
                </a>
              </el-button>
            </div>
            <div v-if="codes.length > 2" class="item-box">
              <div class="code-box">
                <el-image fit="cover" :src="codes[2]"></el-image>
              </div>
              <el-button type="text">
                <a
                  style="text-decoration: none"
                  :href="codes[2]"
                  download="二维码"
                >
                  下载二维码
                </a>
              </el-button>
            </div>
          </div>
        </div>
      </el-form-item>
    </el-form>
  </el-dialog>
</template>

<script>
import { copyText } from '@/base/utils/tool'
export default {
  name: 'TwoCodeDialog',
  props: ['showCode', 'codes'],
  data() {
    return {
      loading: false,
    }
  },
  watch: {
    'codes.length'(newValue, oldValue) {
      if (newValue) {
        this.loading = false
      }
    },
  },
  created() {},
  methods: {
    dialogOpen() {
      this.loading = true
    },
    cancel() {
      this.$emit('closeCode')
      // 重置 codes length 为 0
    },
    // 复制
    copy(url) {
      copyText(url).then((valid) => {
        this.$message.success('复制成功')
      })
    },
  },
}
</script>

<style scoped lang="scss">
.code-box {
  width: 320px;
  height: 217px;
  display: flex;
  align-content: center;
  justify-content: center;
}
a:link {
  color: #3576ff;
}
a:hover {
  color: #5d91ff;
}

.code-box {
  width: 210px;
  height: 210px;
  margin: 10px 0;
  display: flex;
  align-content: center;
  justify-content: center;
}
a:link {
  color: #3576ff;
}
a:hover {
  color: #5d91ff;
}

.code {
  .content-box {
    background-color: #f2f2f2;
    padding: 16px;
    display: flex;
    justify-content: space-around;
    .item-box {
      width: 180px;

      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      .code-box {
        width: 180px;
        height: 180px;
        margin: 10px 0;

        background-color: #fff;

        overflow: hidden;
      }
    }
  }
}
</style>
