/**
 * query字符串转query对象
 * @param {String} queryString or url	queryString指?后面的字符串，如："a=1&b=2"
 * @return {Object}
 */
export function toQueryObject(queryString) {
  const obj = {}
  let qString = queryString
  if (!qString) return {}
  /* 如果给出的链接带?，提取?后面的字符串 */
  if (queryString.indexOf('?') !== -1) {
    const splitArr = queryString.split('?')
    qString = splitArr.length === 2 ? splitArr[1] : ''
    if (!qString) return {}
  }
  if (qString.indexOf('&') === -1) {
    // 只有一对，如："a=1"
    // 如：["a", "1"]
    const arr = qString.split('=')
    obj[arr[0]] = arr[1] || ''
  } else {
    // 有多对，如：["a=1", "b=2"]
    const keyValueArr = qString.split('&')
    keyValueArr.forEach((item) => {
      // 如：["a", "1"]
      const arr = item.split('=')
      obj[arr[0]] = arr[1] || ''
    })
  }

  return obj
}

/**
 * query对象转query字符串，不包括?
 * @param {Object} queryObject
 */
export function toQueryString(queryObject = {}) {
  let str = ''
  Object.keys(queryObject||{}).forEach((key) => {
    const val = queryObject[key]
    // if (queryObject[key]) {
    if (key) str += `${key}=${val !== undefined ? val : ''}&`
    // }
  })
  return str.slice(0, str.length - 1)
}

/**
 * 获取url或queryString中某个key的值
 * @param {String} url
 * @param {String} key
 */
export function getKeyValueByQueryString(url, key) {
  if (!url || !key) return undefined
  const queryObject = toQueryObject(url)
  return queryObject[key]
}

/**
 * 向已有链接追加query字符串，重复的话则替换
 * @param {String} url
 * @param {String} queryString
 * @return {String}	返回新的链接
 */
export function appendQueryStringToUrl(url, queryString) {
  // 得到 ? 前后的字符串组成的数组
  const urlSplit = url.split('?')
  // 原链接是否有query
  const hasQueryStringBefore = urlSplit.length > 1 && urlSplit[1]
  let qString
  if (hasQueryStringBefore) {
    // 合并query，重复key值取最新
    const queryObjectBefore = toQueryObject(urlSplit[1])
    const queryObjectAppend = toQueryObject(queryString)
    const queryObjectResult = { ...queryObjectBefore, ...queryObjectAppend }
    qString = toQueryString(queryObjectResult)
  } else {
    // 原链接没有query，可以直接拼接
    qString = queryString
  }
  return `${urlSplit[0]}?${qString}`
}
